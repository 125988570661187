import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Restore your VGA Camera after a faulty Firmware Upgrade",
  "path": "/Advanced_User/Restore_Firmware/VGA_Models/",
  "dateChanged": "2017-09-26",
  "author": "Mike Polinowski",
  "excerpt": "Your camera appears dead after a firmware update? Learn here how to restore your camera´s operating system.",
  "image": "../AU_SearchThumb_ResetAfterFirmwareError.png",
  "social": "/images/Search/AU_SearchThumb_ResetAfterFirmwareError.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Firmware_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Restore your VGA Camera after a faulty Firmware Upgrade' dateChanged='2017-09-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your camera appears dead after a firmware update? Learn here how to restore your camera´s operating system.' image='/images/Search/AU_SearchThumb_ResetAfterFirmwareError.png' twitter='/images/Search/AU_SearchThumb_ResetAfterFirmwareError.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Firmware_Wiederherstellen/VGA_Modelle/' locationFR='/fr/Advanced_User/Restore_Firmware/VGA_Models/' crumbLabel="Restore FW" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "restore-your-vga-camera-after-a-faulty-firmware-upgrade",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#restore-your-vga-camera-after-a-faulty-firmware-upgrade",
        "aria-label": "restore your vga camera after a faulty firmware upgrade permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Restore your VGA Camera after a faulty Firmware Upgrade`}</h1>
    <h2 {...{
      "id": "vga-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vga-cameras",
        "aria-label": "vga cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VGA Cameras`}</h2>
    <h3 {...{
      "id": "what-do-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-do-you-need",
        "aria-label": "what do you need permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What do you need?`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0454e350da43835d249ec3d43beed91f/f93b5/FWrestore_01_UART.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAagK2hn/xAAYEAADAQEAAAAAAAAAAAAAAAABAgMAEv/aAAgBAQABBQKrMCr0OmTz/8QAFREBAQAAAAAAAAAAAAAAAAAAEEH/2gAIAQMBAT8Bh//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAMBAQAAAAAAAAAAAAAAAAAiMQER/9oACAEBAAY/Al6XRqf/xAAZEAACAwEAAAAAAAAAAAAAAAAAAREhcUH/2gAIAQEAAT8hX5JTFnbDWD//2gAMAwEAAgADAAAAEHAP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QI//EABURAQEAAAAAAAAAAAAAAAAAABBB/9oACAECAQE/EKf/xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMUGhsf/aAAgBAQABPxCUIwnF1L7kgueQo+Yi2tNp11n/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0454e350da43835d249ec3d43beed91f/e4706/FWrestore_01_UART.avif 230w", "/en/static/0454e350da43835d249ec3d43beed91f/e47c0/FWrestore_01_UART.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0454e350da43835d249ec3d43beed91f/a0b58/FWrestore_01_UART.webp 230w", "/en/static/0454e350da43835d249ec3d43beed91f/c85cb/FWrestore_01_UART.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0454e350da43835d249ec3d43beed91f/e83b4/FWrestore_01_UART.jpg 230w", "/en/static/0454e350da43835d249ec3d43beed91f/f93b5/FWrestore_01_UART.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0454e350da43835d249ec3d43beed91f/f93b5/FWrestore_01_UART.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`USB to serial UART Interface (3.3 logic needed) available online.`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/666eb132392ea1a539e0f5ba14af89b9/41099/FWrestore_02_Cable.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHVYREV/8QAFxAAAwEAAAAAAAAAAAAAAAAAABARMf/aAAgBAQABBQLCr//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABcQAQEBAQAAAAAAAAAAAAAAAAAhEVH/2gAIAQEAAT8h2I4V/9oADAMBAAIAAwAAABAHz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/EIf/xAAZEAEBAAMBAAAAAAAAAAAAAAABEQAhgUH/2gAIAQEAAT8QkxXXCmBNeOLTseZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/666eb132392ea1a539e0f5ba14af89b9/e4706/FWrestore_02_Cable.avif 230w", "/en/static/666eb132392ea1a539e0f5ba14af89b9/d1af7/FWrestore_02_Cable.avif 460w", "/en/static/666eb132392ea1a539e0f5ba14af89b9/df5cf/FWrestore_02_Cable.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/666eb132392ea1a539e0f5ba14af89b9/a0b58/FWrestore_02_Cable.webp 230w", "/en/static/666eb132392ea1a539e0f5ba14af89b9/bc10c/FWrestore_02_Cable.webp 460w", "/en/static/666eb132392ea1a539e0f5ba14af89b9/b0a15/FWrestore_02_Cable.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/666eb132392ea1a539e0f5ba14af89b9/e83b4/FWrestore_02_Cable.jpg 230w", "/en/static/666eb132392ea1a539e0f5ba14af89b9/e41a8/FWrestore_02_Cable.jpg 460w", "/en/static/666eb132392ea1a539e0f5ba14af89b9/41099/FWrestore_02_Cable.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/666eb132392ea1a539e0f5ba14af89b9/41099/FWrestore_02_Cable.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`4x connection cable with female pins on both sided and 4 pin plugs.`}</em></p>
    <ul>
      <li parentName="ul">{`Software HyperTerminal (Windows XP) or Software TerraTerm (Vista/7/8):`}</li>
      <li parentName="ul">{`Hyperterminal / Windows XP: Start → Programs → Accessories → Communication → HyperTerminal`}</li>
      <li parentName="ul">{`Tera Term / Windows Vista/7/8: follow the link and download / install the program - `}<a parentName="li" {...{
          "href": "http://en.sourceforge.jp/frs/redir.php?m=jaist&f=%2Fttssh2%2F56762%2Fteraterm-4.75.exe"
        }}>{`CLICK HERE TO DOWNLOAD`}</a></li>
      <li parentName="ul">{`Download the newest firmware and web user interface from our download area. `}<a parentName="li" {...{
          "href": "/en/Downloads/"
        }}>{`CLICK HERE TO DOWNLOAD YOUR FIRMWARE/WEBUI`}</a></li>
      <li parentName="ul">{`Download the linux-romfs.zip container and unpack it. FILE:`}<a parentName="li" {...{
          "href": "https://wiki.instar.com/dl/Developer/Linux-romfs.zip"
        }}>{`LINUX-ROMFS.ZIP`}</a></li>
    </ul>
    <h3 {...{
      "id": "how-to-prepare-your-hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-to-prepare-your-hardware",
        "aria-label": "how to prepare your hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to prepare your Hardware?`}</h3>
    <ul>
      <li parentName="ul">{`Connect your USB to UART interface with the cable. Connect the plug to the GND, Rxd, Txd and 3.3u pins - you may want to solder them in place to make sure that the stay in place.`}</li>
      <li parentName="ul">{`Now connect the other side with the camera mainboard and solder them in place. Make the connection according to:`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Pin #1 Ground → Ground
Pin #2 Rxd → Txd
Pin #3 Txd → Rxd
Pin #4 3.3u → 3.3u`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "250px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f8a4ee769f68bec16df5a6f95aed082d/0479a/FWrestore_06_pin_platine.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "112.6086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAXAQEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHexaYvUDnTJm8Ov//EABoQAAIDAQEAAAAAAAAAAAAAAAABAhESAxP/2gAIAQEAAQUClKhTRaHPRRgbyLsz3P/EABYRAQEBAAAAAAAAAAAAAAAAAAEAEP/aAAgBAwEBPwHQv//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABkQAAIDAQAAAAAAAAAAAAAAAAAxARAhEf/aAAgBAQAGPwK1c5kCF0//xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhYTFBUXH/2gAIAQEAAT8hwdxGoekXK5juN8npUE3Tw1LFAhcA8iafSf/aAAwDAQACAAMAAAAQqyCA/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAREP/aAAgBAwEBPxDguLsCC3//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/ELanFy//xAAaEAEAAwEBAQAAAAAAAAAAAAABABExIXFh/9oACAEBAAE/EB7Fqya1wsusVvRAHD6SkhxD4HYzwCa2HSsbRpyVqwGLffSHSOBxRP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f8a4ee769f68bec16df5a6f95aed082d/e4706/FWrestore_06_pin_platine.avif 230w", "/en/static/f8a4ee769f68bec16df5a6f95aed082d/d3464/FWrestore_06_pin_platine.avif 250w"],
              "sizes": "(max-width: 250px) 100vw, 250px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f8a4ee769f68bec16df5a6f95aed082d/a0b58/FWrestore_06_pin_platine.webp 230w", "/en/static/f8a4ee769f68bec16df5a6f95aed082d/1e0be/FWrestore_06_pin_platine.webp 250w"],
              "sizes": "(max-width: 250px) 100vw, 250px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f8a4ee769f68bec16df5a6f95aed082d/e83b4/FWrestore_06_pin_platine.jpg 230w", "/en/static/f8a4ee769f68bec16df5a6f95aed082d/0479a/FWrestore_06_pin_platine.jpg 250w"],
              "sizes": "(max-width: 250px) 100vw, 250px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f8a4ee769f68bec16df5a6f95aed082d/0479a/FWrestore_06_pin_platine.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0b06dadbb099f40d455f7e70668a05f6/f93b5/FWrestore_07_pin_UART.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGQABAAIDAAAAAAAAAAAAAAAAAAMEAQIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHElCedeirnLUFyBj//xAAcEAABBQADAAAAAAAAAAAAAAABAAIDEBMRITP/2gAIAQEAAQUC3KjedAVwo/XumV//xAAXEQEAAwAAAAAAAAAAAAAAAAAQATFB/9oACAEDAQE/AZow/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHBAAAgAHAAAAAAAAAAAAAAAAAAEREiAxMkFx/9oACAEBAAY/ArjTejMjMPlP/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERIRBRMUHw/9oACAEBAAE/IWylYdqEaJVyIpk1m1PLZC0dsf/aAAwDAQACAAMAAAAQG/CD/8QAGREAAgMBAAAAAAAAAAAAAAAAAAEQETFR/9oACAEDAQE/EE5KFuP/xAAZEQACAwEAAAAAAAAAAAAAAAAAEQEQIUH/2gAIAQIBAT8QejOxX//EAB8QAQABBAEFAAAAAAAAAAAAAAEhABExQYEQUZGh0f/aAAgBAQABPxBoeNtvNNMAkt98Zq8JYu6G3qiVFGRJWlmXkJhePtDZrkakYeKz6P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b06dadbb099f40d455f7e70668a05f6/e4706/FWrestore_07_pin_UART.avif 230w", "/en/static/0b06dadbb099f40d455f7e70668a05f6/e47c0/FWrestore_07_pin_UART.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0b06dadbb099f40d455f7e70668a05f6/a0b58/FWrestore_07_pin_UART.webp 230w", "/en/static/0b06dadbb099f40d455f7e70668a05f6/c85cb/FWrestore_07_pin_UART.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0b06dadbb099f40d455f7e70668a05f6/e83b4/FWrestore_07_pin_UART.jpg 230w", "/en/static/0b06dadbb099f40d455f7e70668a05f6/f93b5/FWrestore_07_pin_UART.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0b06dadbb099f40d455f7e70668a05f6/f93b5/FWrestore_07_pin_UART.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0bce7f57c471f9f06bd442ada1916d46/f93b5/FWrestore_08_Pin_UART2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAVGSeXWyj//EABoQAQABBQAAAAAAAAAAAAAAAAEAAgMQExT/2gAIAQEAAQUCbwPSrulQMQx//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAABAxAf/aAAgBAQAGPwLSOL//xAAaEAACAgMAAAAAAAAAAAAAAAAAARExIUFR/9oACAEBAAE/IVI9ChI2skCwSZxij//aAAwDAQACAAMAAAAQqA//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhUf/aAAgBAwEBPxBQVh//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/ENVL/8QAHBAAAwACAwEAAAAAAAAAAAAAAAERITFBUWGB/9oACAEBAAE/EFJx6OijKoPLnolvZY+4hLCXPBYRKI//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0bce7f57c471f9f06bd442ada1916d46/e4706/FWrestore_08_Pin_UART2.avif 230w", "/en/static/0bce7f57c471f9f06bd442ada1916d46/e47c0/FWrestore_08_Pin_UART2.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0bce7f57c471f9f06bd442ada1916d46/a0b58/FWrestore_08_Pin_UART2.webp 230w", "/en/static/0bce7f57c471f9f06bd442ada1916d46/c85cb/FWrestore_08_Pin_UART2.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0bce7f57c471f9f06bd442ada1916d46/e83b4/FWrestore_08_Pin_UART2.jpg 230w", "/en/static/0bce7f57c471f9f06bd442ada1916d46/f93b5/FWrestore_08_Pin_UART2.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0bce7f57c471f9f06bd442ada1916d46/f93b5/FWrestore_08_Pin_UART2.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/be9450941a2bc68f4fbe54912b9920fd/f93b5/FWrestore_09_UART_LED.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABSp8+d6RCf//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMREiH/2gAIAQEAAQUCDHk2bF5YKPLQJs//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BbH//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPwFH/8QAGBAAAgMAAAAAAAAAAAAAAAAAACEBEEH/2gAIAQEABj8CZgyL/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARITFx/9oACAEBAAE/Ia5A2TMJo6SlypkI8oYsIIJ//9oADAMBAAIAAwAAABAI3//EABgRAAIDAAAAAAAAAAAAAAAAAAABETFh/9oACAEDAQE/EE1FGB//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAECAQE/EEitP//EAB4QAQACAgEFAAAAAAAAAAAAAAEAESFBMWFxgZGx/9oACAEBAAE/ELrQGxx5JUGF6lh07xtHA29a+yojg16gkI3WCAAdT//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be9450941a2bc68f4fbe54912b9920fd/e4706/FWrestore_09_UART_LED.avif 230w", "/en/static/be9450941a2bc68f4fbe54912b9920fd/e47c0/FWrestore_09_UART_LED.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/be9450941a2bc68f4fbe54912b9920fd/a0b58/FWrestore_09_UART_LED.webp 230w", "/en/static/be9450941a2bc68f4fbe54912b9920fd/c85cb/FWrestore_09_UART_LED.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/be9450941a2bc68f4fbe54912b9920fd/e83b4/FWrestore_09_UART_LED.jpg 230w", "/en/static/be9450941a2bc68f4fbe54912b9920fd/f93b5/FWrestore_09_UART_LED.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/be9450941a2bc68f4fbe54912b9920fd/f93b5/FWrestore_09_UART_LED.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Make sure that the connection is solid and that you don´t have a loose contact somewhere.`}</li>
      <li parentName="ul">{`The UART Status LED should light up after plugging in the USB connector (depends on the model you are using).`}</li>
    </ul>
    <h2 {...{
      "id": "operating-system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#operating-system",
        "aria-label": "operating system permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Operating System`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pour-les-utilisateurs-windows-xp-hyperterminal"
        }}>{`Windows XP`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#pour-les-utilisateurs-windows-vista78---teraterm"
        }}>{`Windows 7, 8`}</a></li>
    </ul>
    <h3 {...{
      "id": "for-windows-xp-user---hyperterminal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#for-windows-xp-user---hyperterminal",
        "aria-label": "for windows xp user   hyperterminal permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`For Windows XP-User - HyperTerminal`}</h3>
    <p>{`First you have to define a new connection after starting up the HyperTerminal. Type in any name and click on OK.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/75437d32dabb68a76f31f257a4089167/f93b5/FWrestore_10_HT_1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "90.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAH2bzppoUAH/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAEiEBESICH/2gAIAQEAAQUC7TFE7yFp/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGRAAAwADAAAAAAAAAAAAAAAAAAExEBEg/9oACAEBAAY/AsQc0Vc//8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFhIFGRwf/aAAgBAQABPyGbYd9FbPUQjpkdUZ/I2c8P/9oADAMBAAIAAwAAABD7Hzz/xAAYEQACAwAAAAAAAAAAAAAAAAAAARARUf/aAAgBAwEBPxAt7P8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAEQAQ/9oACAECAQE/ECN//8QAHBABAAICAwEAAAAAAAAAAAAAAREhADEQQVGR/9oACAEBAAE/EJkqGkKjxjBCIfLgSoCUtu8C5pAohGl7vED12g60R37OThSm0lVxB4ZAaIz/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75437d32dabb68a76f31f257a4089167/e4706/FWrestore_10_HT_1.avif 230w", "/en/static/75437d32dabb68a76f31f257a4089167/e47c0/FWrestore_10_HT_1.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/75437d32dabb68a76f31f257a4089167/a0b58/FWrestore_10_HT_1.webp 230w", "/en/static/75437d32dabb68a76f31f257a4089167/c85cb/FWrestore_10_HT_1.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75437d32dabb68a76f31f257a4089167/e83b4/FWrestore_10_HT_1.jpg 230w", "/en/static/75437d32dabb68a76f31f257a4089167/f93b5/FWrestore_10_HT_1.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/75437d32dabb68a76f31f257a4089167/f93b5/FWrestore_10_HT_1.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c180d8dc463d0687eb216273043da445/f93b5/FWrestore_11_HT_2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "99.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHs1NELRuFAf//EABoQAAICAwAAAAAAAAAAAAAAAAARAQISIEH/2gAIAQEAAQUCctmNTuv/xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8BK//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABgQAAIDAAAAAAAAAAAAAAAAAAERECAx/9oACAEBAAY/Ai1GX//EABsQAAMAAgMAAAAAAAAAAAAAAAABESFBEDFh/9oACAEBAAE/IVkEXBC2LUNhTmes6P/aAAwDAQACAAMAAAAQg/A8/8QAFxEAAwEAAAAAAAAAAAAAAAAAABARUf/aAAgBAwEBPxArX//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxAj/8QAHhABAAICAgMBAAAAAAAAAAAAAQARITFhkRBBUXH/2gAIAQEAAT8QEXwUbCvcNLtcy0MtuXcULFr91EDDuCOvHY5gUq15Z//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c180d8dc463d0687eb216273043da445/e4706/FWrestore_11_HT_2.avif 230w", "/en/static/c180d8dc463d0687eb216273043da445/e47c0/FWrestore_11_HT_2.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c180d8dc463d0687eb216273043da445/a0b58/FWrestore_11_HT_2.webp 230w", "/en/static/c180d8dc463d0687eb216273043da445/c85cb/FWrestore_11_HT_2.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c180d8dc463d0687eb216273043da445/e83b4/FWrestore_11_HT_2.jpg 230w", "/en/static/c180d8dc463d0687eb216273043da445/f93b5/FWrestore_11_HT_2.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c180d8dc463d0687eb216273043da445/f93b5/FWrestore_11_HT_2.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Select the COM port of your UART interface and confirm with OK. You can check the COM port under Control Panel → Device Manager → Ports COM & LTP. Adjust the settings according to the following screenshot and click OK to confirm.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d6674753ae0c979da0bc17e4318a7b69/70ebb/FWrestore_12_HT_3.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "116.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe7LDLYZAD//xAAZEAABBQAAAAAAAAAAAAAAAAAQAAIREkH/2gAIAQEAAQUCFGrDI//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEr/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFhAAAwAAAAAAAAAAAAAAAAAAECAx/9oACAEBAAY/AhH/AP/EAB4QAAICAAcAAAAAAAAAAAAAAAABESEQMVFhgZGh/9oACAEBAAE/IefBJb9DkmNjwMmsh6KE5R//2gAMAwEAAgADAAAAECAfPP/EABcRAAMBAAAAAAAAAAAAAAAAAAAQEVH/2gAIAQMBAT8QK1//xAAWEQADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8QI//EAB0QAQACAgMBAQAAAAAAAAAAAAEAESExQVFxYdH/2gAIAQEAAT8QrtImoDkpxtLBK5Km2UpdMKrY+V+zh5EmVqllAy+DKBb66n//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6674753ae0c979da0bc17e4318a7b69/e4706/FWrestore_12_HT_3.avif 230w", "/en/static/d6674753ae0c979da0bc17e4318a7b69/7bd34/FWrestore_12_HT_3.avif 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d6674753ae0c979da0bc17e4318a7b69/a0b58/FWrestore_12_HT_3.webp 230w", "/en/static/d6674753ae0c979da0bc17e4318a7b69/a3432/FWrestore_12_HT_3.webp 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d6674753ae0c979da0bc17e4318a7b69/e83b4/FWrestore_12_HT_3.jpg 230w", "/en/static/d6674753ae0c979da0bc17e4318a7b69/70ebb/FWrestore_12_HT_3.jpg 350w"],
              "sizes": "(max-width: 350px) 100vw, 350px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d6674753ae0c979da0bc17e4318a7b69/70ebb/FWrestore_12_HT_3.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/962cf14807e6d13f36da7d1d916860d9/41099/FWrestore_14_HT_5.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHXm7BDD//EABkQAAIDAQAAAAAAAAAAAAAAAAERAAIiEP/aAAgBAQABBQJaroKDv//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABUQAQEAAAAAAAAAAAAAAAAAABEg/9oACAEBAAY/Aiv/xAAaEAADAQADAAAAAAAAAAAAAAAAAREhEEFR/9oACAEBAAE/IUvZgqpCPDStEpx//9oADAMBAAIAAwAAABCjz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFBEFFhgf/aAAgBAQABPxDP2gE9xLLoXs3O0PyFvIfIAo4//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/962cf14807e6d13f36da7d1d916860d9/e4706/FWrestore_14_HT_5.avif 230w", "/en/static/962cf14807e6d13f36da7d1d916860d9/d1af7/FWrestore_14_HT_5.avif 460w", "/en/static/962cf14807e6d13f36da7d1d916860d9/df5cf/FWrestore_14_HT_5.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/962cf14807e6d13f36da7d1d916860d9/a0b58/FWrestore_14_HT_5.webp 230w", "/en/static/962cf14807e6d13f36da7d1d916860d9/bc10c/FWrestore_14_HT_5.webp 460w", "/en/static/962cf14807e6d13f36da7d1d916860d9/b0a15/FWrestore_14_HT_5.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/962cf14807e6d13f36da7d1d916860d9/e83b4/FWrestore_14_HT_5.jpg 230w", "/en/static/962cf14807e6d13f36da7d1d916860d9/e41a8/FWrestore_14_HT_5.jpg 460w", "/en/static/962cf14807e6d13f36da7d1d916860d9/41099/FWrestore_14_HT_5.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/962cf14807e6d13f36da7d1d916860d9/41099/FWrestore_14_HT_5.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Save your settings under File → Save As`}</li>
      <li parentName="ul">{`Now open the connection by double-clicking the shortcut on your desktop that was created in the previous step.`}</li>
      <li parentName="ul">{`The program should automatically launch the mainboards bootloader - then press ESC. You should now see the following screen.`}</li>
      <li parentName="ul">{`Now type in the following two commands: del 6 `}<em parentName="li">{`Enter`}</em>{` THEN del 7 `}<em parentName="li">{`Enter`}</em>{` (Don´t forget the SPACE!!!)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/696f85c383121f1411c747f48dd447e2/41099/FWrestore_15_HT_6.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHakrRDD//EABgQAAIDAAAAAAAAAAAAAAAAAAACEDFC/9oACAEBAAEFAtLQs//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAAMAAAAAAAAAAAAAAAAAAAAgIf/aAAgBAQAGPwIq/wD/xAAZEAEAAwEBAAAAAAAAAAAAAAABEBEhAFH/2gAIAQEAAT8hMtlcU9IM/wD/2gAMAwEAAgADAAAAECMP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAIBBQAAAAAAAAAAAAAAAQARIRBRYYGR/9oACAEBAAE/EAK20o4xtM03Z8lQgXrX/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/696f85c383121f1411c747f48dd447e2/e4706/FWrestore_15_HT_6.avif 230w", "/en/static/696f85c383121f1411c747f48dd447e2/d1af7/FWrestore_15_HT_6.avif 460w", "/en/static/696f85c383121f1411c747f48dd447e2/df5cf/FWrestore_15_HT_6.avif 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/696f85c383121f1411c747f48dd447e2/a0b58/FWrestore_15_HT_6.webp 230w", "/en/static/696f85c383121f1411c747f48dd447e2/bc10c/FWrestore_15_HT_6.webp 460w", "/en/static/696f85c383121f1411c747f48dd447e2/b0a15/FWrestore_15_HT_6.webp 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/696f85c383121f1411c747f48dd447e2/e83b4/FWrestore_15_HT_6.jpg 230w", "/en/static/696f85c383121f1411c747f48dd447e2/e41a8/FWrestore_15_HT_6.jpg 460w", "/en/static/696f85c383121f1411c747f48dd447e2/41099/FWrestore_15_HT_6.jpg 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/696f85c383121f1411c747f48dd447e2/41099/FWrestore_15_HT_6.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b9575d16956d5a88b5223571e6851b9/066f9/FWrestore_16_HT_7.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.217391304347828%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdWQWAf/xAAWEAADAAAAAAAAAAAAAAAAAAAAEBH/2gAIAQEAAQUCKv/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABgQAAMBAQAAAAAAAAAAAAAAAAABETHw/9oACAEBAAE/IYVyFh//2gAMAwEAAgADAAAAEIAP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAIDAQAAAAAAAAAAAAAAAQARITFBkf/aAAgBAQABPxAAUamTniECABXCf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b9575d16956d5a88b5223571e6851b9/e4706/FWrestore_16_HT_7.avif 230w", "/en/static/9b9575d16956d5a88b5223571e6851b9/af71e/FWrestore_16_HT_7.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b9575d16956d5a88b5223571e6851b9/a0b58/FWrestore_16_HT_7.webp 230w", "/en/static/9b9575d16956d5a88b5223571e6851b9/7f61c/FWrestore_16_HT_7.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b9575d16956d5a88b5223571e6851b9/e83b4/FWrestore_16_HT_7.jpg 230w", "/en/static/9b9575d16956d5a88b5223571e6851b9/066f9/FWrestore_16_HT_7.jpg 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b9575d16956d5a88b5223571e6851b9/066f9/FWrestore_16_HT_7.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Now type in the following: fx 6 romfs.img 0x7f0e0000 0x7f0e0000 -a `}<em parentName="li">{`Enter`}</em>{` Waiting for download... will be displayed.`}</li>
      <li parentName="ul">{`Select the romfs.img file you downloaded earlier in Transfer → Send file → choose the romfs image file by browsing to the location you extracted it to earlier. Then set the protocol to Xmodem and click on Send.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/abd3a5a80d464ab559f0e7dc5e41b301/b4294/FWrestore_17_HT_8.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHuL5Eg/8QAGBAAAgMAAAAAAAAAAAAAAAAAACEQEUH/2gAIAQEAAQUCoQjI/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERITFRof/aAAgBAQABPyFZWsnYxl8FwJBH/9oADAMBAAIAAwAAABDT3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAAMAAwADAAAAAAAAAAAAAAABESExQWFxof/aAAgBAQABPxBdOA+yZ8IrHTA+RErVvZcfs//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abd3a5a80d464ab559f0e7dc5e41b301/e4706/FWrestore_17_HT_8.avif 230w", "/en/static/abd3a5a80d464ab559f0e7dc5e41b301/d1af7/FWrestore_17_HT_8.avif 460w", "/en/static/abd3a5a80d464ab559f0e7dc5e41b301/56a33/FWrestore_17_HT_8.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/abd3a5a80d464ab559f0e7dc5e41b301/a0b58/FWrestore_17_HT_8.webp 230w", "/en/static/abd3a5a80d464ab559f0e7dc5e41b301/bc10c/FWrestore_17_HT_8.webp 460w", "/en/static/abd3a5a80d464ab559f0e7dc5e41b301/e88ff/FWrestore_17_HT_8.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abd3a5a80d464ab559f0e7dc5e41b301/e83b4/FWrestore_17_HT_8.jpg 230w", "/en/static/abd3a5a80d464ab559f0e7dc5e41b301/e41a8/FWrestore_17_HT_8.jpg 460w", "/en/static/abd3a5a80d464ab559f0e7dc5e41b301/b4294/FWrestore_17_HT_8.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/abd3a5a80d464ab559f0e7dc5e41b301/b4294/FWrestore_17_HT_8.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1466f8c14aaebd096c9e1f37238999e9/20e5d/FWrestore_18_HT_9.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAexWdTQf/8QAGBAAAgMAAAAAAAAAAAAAAAAAARAAAhH/2gAIAQEAAQUCDqJi/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/AiL/AP/EABsQAAEEAwAAAAAAAAAAAAAAABEAARAhMUGR/9oACAEBAAE/IXkFU23IwW6CP//aAAwDAQACAAMAAAAQs8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAeEAACAQMFAAAAAAAAAAAAAAAAAREhMVEQQWFxkf/aAAgBAQABPxBCJa8IW12CFgtGyOSpNfXp/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1466f8c14aaebd096c9e1f37238999e9/e4706/FWrestore_18_HT_9.avif 230w", "/en/static/1466f8c14aaebd096c9e1f37238999e9/e46ac/FWrestore_18_HT_9.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1466f8c14aaebd096c9e1f37238999e9/a0b58/FWrestore_18_HT_9.webp 230w", "/en/static/1466f8c14aaebd096c9e1f37238999e9/8626f/FWrestore_18_HT_9.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1466f8c14aaebd096c9e1f37238999e9/e83b4/FWrestore_18_HT_9.jpg 230w", "/en/static/1466f8c14aaebd096c9e1f37238999e9/20e5d/FWrestore_18_HT_9.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1466f8c14aaebd096c9e1f37238999e9/20e5d/FWrestore_18_HT_9.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`After the upload is completed repeat these steps for the Linux.zip file. Type in: fx 7 linux.zip 0x7f020000 0x8000 -acxz `}<em parentName="li">{`Enter`}</em></li>
      <li parentName="ul">{`Choose the file linux.zip from the earlier download and select the protocol Xmodem.`}</li>
      <li parentName="ul">{`Now type in boot and confirm with Enter.`}</li>
      <li parentName="ul">{`You can now reassemble your camera and check if you can connect it to your local cable network.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3b14ff92ab6de1b84ab9e5fd141d3156/20e5d/FWrestore_19_TT_1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdtElDD/xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAEFAl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAADAQEAAAAAAAAAAAAAAAAAARExQf/aAAgBAQABPyGCUWET4MiMP//aAAwDAQACAAMAAAAQIw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAcEAEAAgMAAwAAAAAAAAAAAAABABEhMUFRYXH/2gAIAQEAAT8Q0e3yZjb5NhN+yADBtzDxdgAoKJ//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3b14ff92ab6de1b84ab9e5fd141d3156/e4706/FWrestore_19_TT_1.avif 230w", "/en/static/3b14ff92ab6de1b84ab9e5fd141d3156/e46ac/FWrestore_19_TT_1.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3b14ff92ab6de1b84ab9e5fd141d3156/a0b58/FWrestore_19_TT_1.webp 230w", "/en/static/3b14ff92ab6de1b84ab9e5fd141d3156/8626f/FWrestore_19_TT_1.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3b14ff92ab6de1b84ab9e5fd141d3156/e83b4/FWrestore_19_TT_1.jpg 230w", "/en/static/3b14ff92ab6de1b84ab9e5fd141d3156/20e5d/FWrestore_19_TT_1.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3b14ff92ab6de1b84ab9e5fd141d3156/20e5d/FWrestore_19_TT_1.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b496e58da977653591a0aadb2e7edcf/b4294/FWrestore_20_TT_2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQBAgP/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHVZpYgsH//xAAaEAACAwEBAAAAAAAAAAAAAAACAwABBBIT/9oACAEBAAEFAsbDObXMW9DTMMc21XvnquP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIRA3ESIYH/2gAIAQEABj8Cnyk3TKhNpUNylfZk2eD2f//EABkQAQADAQEAAAAAAAAAAAAAAAEAESExQf/aAAgBAQABPyHwcJbyYyK0RoSxbABQJRoHMBgR/9oADAMBAAIAAwAAABCgH//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxBtf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQADAQEBAQAAAAAAAAAAAAEAESExYVFx/9oACAEBAAE/EDXWlpjcJ2UicvdhNehQtUeSmljh+y9qdUv7DIIvw8J//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b496e58da977653591a0aadb2e7edcf/e4706/FWrestore_20_TT_2.avif 230w", "/en/static/9b496e58da977653591a0aadb2e7edcf/d1af7/FWrestore_20_TT_2.avif 460w", "/en/static/9b496e58da977653591a0aadb2e7edcf/56a33/FWrestore_20_TT_2.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b496e58da977653591a0aadb2e7edcf/a0b58/FWrestore_20_TT_2.webp 230w", "/en/static/9b496e58da977653591a0aadb2e7edcf/bc10c/FWrestore_20_TT_2.webp 460w", "/en/static/9b496e58da977653591a0aadb2e7edcf/e88ff/FWrestore_20_TT_2.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b496e58da977653591a0aadb2e7edcf/e83b4/FWrestore_20_TT_2.jpg 230w", "/en/static/9b496e58da977653591a0aadb2e7edcf/e41a8/FWrestore_20_TT_2.jpg 460w", "/en/static/9b496e58da977653591a0aadb2e7edcf/b4294/FWrestore_20_TT_2.jpg 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b496e58da977653591a0aadb2e7edcf/b4294/FWrestore_20_TT_2.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "for-windows-vista78-user---teraterm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#for-windows-vista78-user---teraterm",
        "aria-label": "for windows vista78 user   teraterm permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`For Windows Vista/7/8 User - TeraTerm`}</h3>
    <ul>
      <li parentName="ul">{`After starting TeraTerm you first have to set up a new connection. Choose a serial connection (Seriell) and select the port the UART interface is connected to. Confirm these settings with OK. You can check the COM port under Control * Panel → Device Manager → Ports COM & LTP.`}</li>
      <li parentName="ul">{`Now go to Einstellungen (Settings) and choose Serieller Port (Serial Port).`}</li>
      <li parentName="ul">{`Adjust the settings according to the following screenshot and click OK to confirm.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9367ab1e882c928a9c06a474473c929/066f9/FWrestore_20_TT_3.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "83.04347826086956%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB25AsAAf/xAAXEAEBAQEAAAAAAAAAAAAAAAABABEg/9oACAEBAAEFAosHv//EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwEr/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFxAAAwEAAAAAAAAAAAAAAAAAARAgIf/aAAgBAQAGPwJaL//EABwQAQACAgMBAAAAAAAAAAAAAAEAESFhIDFB4f/aAAgBAQABPyG7UixdZ1O4K7I2K+QoPkMHD//aAAwDAQACAAMAAAAQMx88/8QAFxEAAwEAAAAAAAAAAAAAAAAAABARUf/aAAgBAwEBPxArX//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABsQAQADAQADAAAAAAAAAAAAAAEAESExIGFx/9oACAEBAAE/ELtAh7lvNrNQvuvXVlGCrtQDtU+oAAKDw//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9367ab1e882c928a9c06a474473c929/e4706/FWrestore_20_TT_3.avif 230w", "/en/static/a9367ab1e882c928a9c06a474473c929/af71e/FWrestore_20_TT_3.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9367ab1e882c928a9c06a474473c929/a0b58/FWrestore_20_TT_3.webp 230w", "/en/static/a9367ab1e882c928a9c06a474473c929/7f61c/FWrestore_20_TT_3.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9367ab1e882c928a9c06a474473c929/e83b4/FWrestore_20_TT_3.jpg 230w", "/en/static/a9367ab1e882c928a9c06a474473c929/066f9/FWrestore_20_TT_3.jpg 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9367ab1e882c928a9c06a474473c929/066f9/FWrestore_20_TT_3.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2f76f0d4d9a6631e44a4f0aba7eb9ef2/20e5d/FWrestore_21_TT_7.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.43478260869566%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAQBAgMG/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABzXhY6UoH/8QAGxAAAgMAAwAAAAAAAAAAAAAAAgMAAQQFEhT/2gAIAQEAAQUCZrYJe50yHbksEu5CU4+rrP8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHxAAAQIGAwAAAAAAAAAAAAAAAAERAhIhMVGRIjNB/9oACAEBAAY/AltfB5omiZ3IuK3wda6K0qf/xAAbEAEAAgIDAAAAAAAAAAAAAAABACERMUFRcf/aAAgBAQABPyEpGiCMTJgdkom9d+XcWO31MDKs6Z//2gAMAwEAAgADAAAAECAf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EG1//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAAMAAQUAAAAAAAAAAAAAAQARIUExUWGBsf/aAAgBAQABPxCuPJbwZeb7zclUZGVEqCVKbl4gsCnrt8m7o6aeO8//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f76f0d4d9a6631e44a4f0aba7eb9ef2/e4706/FWrestore_21_TT_7.avif 230w", "/en/static/2f76f0d4d9a6631e44a4f0aba7eb9ef2/e46ac/FWrestore_21_TT_7.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2f76f0d4d9a6631e44a4f0aba7eb9ef2/a0b58/FWrestore_21_TT_7.webp 230w", "/en/static/2f76f0d4d9a6631e44a4f0aba7eb9ef2/8626f/FWrestore_21_TT_7.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2f76f0d4d9a6631e44a4f0aba7eb9ef2/e83b4/FWrestore_21_TT_7.jpg 230w", "/en/static/2f76f0d4d9a6631e44a4f0aba7eb9ef2/20e5d/FWrestore_21_TT_7.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2f76f0d4d9a6631e44a4f0aba7eb9ef2/20e5d/FWrestore_21_TT_7.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`The program should automatically launch the mainboards bootloader - then press ESC. You should now see the following screen.`}</li>
      <li parentName="ul">{`Now type in the following two commands: del 6 `}<em parentName="li">{`Enter`}</em>{` THEN del 7 `}<em parentName="li">{`Enter`}</em>{` (Don´t forget the SPACE!!!)`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dccd3f8286302b82617390b15d99d3e2/20e5d/FWrestore_22_TT_8.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.56521739130434%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHOdZTpxA//xAAcEAACAgIDAAAAAAAAAAAAAAABAwIEAAURExT/2gAIAQEAAQUCZacGe5+VJlyWg9pjLNeOK3//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAeEAABAwQDAAAAAAAAAAAAAAAAAQIREiFRkTEyQf/aAAgBAQAGPwJyJFlweaKnRMj7Lzg6rovk/8QAGhAAAwEBAQEAAAAAAAAAAAAAAAERITFBUf/aAAgBAQABPyHPKxIQKQ6NxQyHf6+k2BZJGnfT/9oADAMBAAIAAwAAABBj3//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxBtf//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExQVFh8P/aAAgBAQABPxCijVxYOu8Wtma+3GM6nUZMo0byJdvWKh98owpw0iPWf//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dccd3f8286302b82617390b15d99d3e2/e4706/FWrestore_22_TT_8.avif 230w", "/en/static/dccd3f8286302b82617390b15d99d3e2/e46ac/FWrestore_22_TT_8.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dccd3f8286302b82617390b15d99d3e2/a0b58/FWrestore_22_TT_8.webp 230w", "/en/static/dccd3f8286302b82617390b15d99d3e2/8626f/FWrestore_22_TT_8.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dccd3f8286302b82617390b15d99d3e2/e83b4/FWrestore_22_TT_8.jpg 230w", "/en/static/dccd3f8286302b82617390b15d99d3e2/20e5d/FWrestore_22_TT_8.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dccd3f8286302b82617390b15d99d3e2/20e5d/FWrestore_22_TT_8.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0a33054d67f7db242d287a7af8189f86/20e5d/FWrestore_23_TT_9.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAMF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABxJAB/8QAFhAAAwAAAAAAAAAAAAAAAAAAAhAh/9oACAEBAAEFAij/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABYQAAMAAAAAAAAAAAAAAAAAAAEQQf/aAAgBAQABPyEof//aAAwDAQACAAMAAAAQ8A//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEAAgMAAAAAAAAAAAAAAAABABEQMWH/2gAIAQEAAT8QJFaXkVdt4//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a33054d67f7db242d287a7af8189f86/e4706/FWrestore_23_TT_9.avif 230w", "/en/static/0a33054d67f7db242d287a7af8189f86/e46ac/FWrestore_23_TT_9.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0a33054d67f7db242d287a7af8189f86/a0b58/FWrestore_23_TT_9.webp 230w", "/en/static/0a33054d67f7db242d287a7af8189f86/8626f/FWrestore_23_TT_9.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a33054d67f7db242d287a7af8189f86/e83b4/FWrestore_23_TT_9.jpg 230w", "/en/static/0a33054d67f7db242d287a7af8189f86/20e5d/FWrestore_23_TT_9.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0a33054d67f7db242d287a7af8189f86/20e5d/FWrestore_23_TT_9.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Now type: fx 6 romfs.img 0x7f0e0000 0x7f0e0000 -a `}<em parentName="li">{`Enter`}</em>{` . The program now waits for the file to transfer.`}</li>
      <li parentName="ul">{`Now go to Datei → Transfer → XMODEM → Senden and browse for the romfs.img you downloaded in the beginning. Click on Open to start the file transfer.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/404558e4f6057636b517ab7b0938480f/20e5d/FWrestore_24_TT_10.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "14.782608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAcyAVgf/xAAWEAEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQEAAQUCYr//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAQ/9oACAEBAAY/An//xAAaEAABBQEAAAAAAAAAAAAAAAAAAREhQWGR/9oACAEBAAE/IbGb0VJ//9oADAMBAAIAAwAAABADz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQEBAQEBAAAAAAAAAAAAAAERADEhcf/aAAgBAQABPxBBp7peDH3kWqrerd//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/404558e4f6057636b517ab7b0938480f/e4706/FWrestore_24_TT_10.avif 230w", "/en/static/404558e4f6057636b517ab7b0938480f/e46ac/FWrestore_24_TT_10.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/404558e4f6057636b517ab7b0938480f/a0b58/FWrestore_24_TT_10.webp 230w", "/en/static/404558e4f6057636b517ab7b0938480f/8626f/FWrestore_24_TT_10.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/404558e4f6057636b517ab7b0938480f/e83b4/FWrestore_24_TT_10.jpg 230w", "/en/static/404558e4f6057636b517ab7b0938480f/20e5d/FWrestore_24_TT_10.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/404558e4f6057636b517ab7b0938480f/20e5d/FWrestore_24_TT_10.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b500e87ecf1f8ec9069beb511cdf8711/20e5d/FWrestore_25_TT_4.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHZwt2o60RVcEgf/8QAHRAAAAUFAAAAAAAAAAAAAAAAAAECEUEDBBAUMf/aAAgBAQABBQKV3FXbEmgnbBcH/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHBAAAQQDAQAAAAAAAAAAAAAAABAREjEBEyEi/9oACAEBAAY/AjXLzJqV4dLMr//EABsQAAICAwEAAAAAAAAAAAAAAAERAFEQITFB/9oACAEBAAE/IVaBEjWiceANocQCbIRrz2OeP//aAAwDAQACAAMAAAAQswgA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHRAAAgIDAAMAAAAAAAAAAAAAAREAMRAhQaGxwf/aAAgBAQABPxBiWyHDANJtZKBaeAt44XdiCDudu4F/AT2Txcf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b500e87ecf1f8ec9069beb511cdf8711/e4706/FWrestore_25_TT_4.avif 230w", "/en/static/b500e87ecf1f8ec9069beb511cdf8711/e46ac/FWrestore_25_TT_4.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b500e87ecf1f8ec9069beb511cdf8711/a0b58/FWrestore_25_TT_4.webp 230w", "/en/static/b500e87ecf1f8ec9069beb511cdf8711/8626f/FWrestore_25_TT_4.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b500e87ecf1f8ec9069beb511cdf8711/e83b4/FWrestore_25_TT_4.jpg 230w", "/en/static/b500e87ecf1f8ec9069beb511cdf8711/20e5d/FWrestore_25_TT_4.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b500e87ecf1f8ec9069beb511cdf8711/20e5d/FWrestore_25_TT_4.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/47ddd4c51192d398b0c28a0312515cd5/066f9/FWrestore_26_TT_5.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "81.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAQABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB24oMo//EABcQAAMBAAAAAAAAAAAAAAAAABARICH/2gAIAQEAAQUC0KP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAAIQECIP/aAAgBAQAGPwIUDrj/xAAdEAEAAQQDAQAAAAAAAAAAAAABABExQWEQIXHw/9oACAEBAAE/IQyGnsR2TvYXc+twVzP/2gAMAwEAAgADAAAAEPMf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/EG1//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGxABAQACAwEAAAAAAAAAAAAAAREAITFBUYH/2gAIAQEAAT8Q2BXTSOTVQPoYnfsjC5Ku+HdwE4h8wRtW5//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47ddd4c51192d398b0c28a0312515cd5/e4706/FWrestore_26_TT_5.avif 230w", "/en/static/47ddd4c51192d398b0c28a0312515cd5/af71e/FWrestore_26_TT_5.avif 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/47ddd4c51192d398b0c28a0312515cd5/a0b58/FWrestore_26_TT_5.webp 230w", "/en/static/47ddd4c51192d398b0c28a0312515cd5/7f61c/FWrestore_26_TT_5.webp 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47ddd4c51192d398b0c28a0312515cd5/e83b4/FWrestore_26_TT_5.jpg 230w", "/en/static/47ddd4c51192d398b0c28a0312515cd5/066f9/FWrestore_26_TT_5.jpg 400w"],
              "sizes": "(max-width: 400px) 100vw, 400px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/47ddd4c51192d398b0c28a0312515cd5/066f9/FWrestore_26_TT_5.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "300px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/528a97966c48cea566cc9597f22b7d12/f93b5/FWrestore_27_TT_6.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.52173913043477%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdhUxFh//8QAGRABAAIDAAAAAAAAAAAAAAAAAQAREBIi/9oACAEBAAEFAr6LmpEhj//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/AVf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAADAQAAAAAAAAAAAAAAAAAAETEg/9oACAEBAAY/AkQmP//EABsQAQEBAAIDAAAAAAAAAAAAAAERABAxQWFx/9oACAEBAAE/IXuSb0H44Wqa94Vs1eScf//aAAwDAQACAAMAAAAQYN//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEVH/2gAIAQMBAT8QVr//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAHBABAAICAwEAAAAAAAAAAAAAAQARIUExUcHh/9oACAEBAAE/EGXRG3HsDVRPD9YqPa9rYsVmJuUnaeQn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/528a97966c48cea566cc9597f22b7d12/e4706/FWrestore_27_TT_6.avif 230w", "/en/static/528a97966c48cea566cc9597f22b7d12/e47c0/FWrestore_27_TT_6.avif 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/528a97966c48cea566cc9597f22b7d12/a0b58/FWrestore_27_TT_6.webp 230w", "/en/static/528a97966c48cea566cc9597f22b7d12/c85cb/FWrestore_27_TT_6.webp 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/528a97966c48cea566cc9597f22b7d12/e83b4/FWrestore_27_TT_6.jpg 230w", "/en/static/528a97966c48cea566cc9597f22b7d12/f93b5/FWrestore_27_TT_6.jpg 300w"],
              "sizes": "(max-width: 300px) 100vw, 300px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/528a97966c48cea566cc9597f22b7d12/f93b5/FWrestore_27_TT_6.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`Now we have to repeat those steps for the Linux.zip file. Type: fx 7 linux.zip 0x7f020000 0x8000 -acxz `}<em parentName="li">{`Enter`}</em>{` . and choose the file linux.zip.`}</li>
      <li parentName="ul">{`Afterwards, type boot and confirm with `}<em parentName="li">{`Enter`}</em>{`.`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "450px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed78bd17f27396c4bedee1f6085867a5/20e5d/FWrestore_28_TT_11.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "64.34782608695653%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeNKkjAf/8QAGBAAAgMAAAAAAAAAAAAAAAAAARAAAhH/2gAIAQEAAQUCLsZq/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/Air/AP/EABsQAAEEAwAAAAAAAAAAAAAAABEAARAhMUGR/9oACAEBAAE/IWghW+nYyUyKP//aAAwDAQACAAMAAAAQc8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAeEAACAQMFAAAAAAAAAAAAAAAAAREhMVEQQWFxkf/aAAgBAQABPxBjNesse90CXll4kTwUIp4tP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed78bd17f27396c4bedee1f6085867a5/e4706/FWrestore_28_TT_11.avif 230w", "/en/static/ed78bd17f27396c4bedee1f6085867a5/e46ac/FWrestore_28_TT_11.avif 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed78bd17f27396c4bedee1f6085867a5/a0b58/FWrestore_28_TT_11.webp 230w", "/en/static/ed78bd17f27396c4bedee1f6085867a5/8626f/FWrestore_28_TT_11.webp 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed78bd17f27396c4bedee1f6085867a5/e83b4/FWrestore_28_TT_11.jpg 230w", "/en/static/ed78bd17f27396c4bedee1f6085867a5/20e5d/FWrestore_28_TT_11.jpg 450w"],
              "sizes": "(max-width: 450px) 100vw, 450px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed78bd17f27396c4bedee1f6085867a5/20e5d/FWrestore_28_TT_11.jpg",
              "alt": "Firmware Upgrade",
              "title": "Firmware Upgrade",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul">{`You can now reassemble your camera and check if you can connect it to your local cable network.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      